import React from 'react';
import PropTypes from 'prop-types';
import './Highlight.scss';

export const Highlight = ({
  color,
  imageFile,
  imageAltText,
  headerText,
  text
}) => (
  <div className={`Highlight bg-${color}`}>
    <div className="Highlight__inner">
      <div className="Highlight__image">
        <img src={ imageFile } alt={ imageAltText} />
      </div>
      <div className="Highlight__text-wrapper">
        <h2 className="Highlight__header-text">
          { headerText }
        </h2>
        <div className="Highlight__text">
          { text }
        </div>
      </div>
    </div>
  </div>
)

export const HIGHLIGHT_SHAPE = {
  imageFile: PropTypes.string,
  imageAltText: PropTypes.string,
  headerText: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.oneOf(['0', '1', '2', '3', '4']),
};

Highlight.propTypes = HIGHLIGHT_SHAPE;
Highlight.defaultProps = {
  imageFile: '',
  imageAltText: '',
  headerText: '',
  text: '',
  color: '0',
}
