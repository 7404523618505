import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import './Banner.scss';

const Banner = ({
  headerText,
  subtitle,
  buttonLink,
  buttonText,
  imgFile,
  imgAltText
}) => (
  <section className="Banner">
    <div className="Banner__wrapper">
      <div className="Banner__text">
        <h1 className="Banner__title">
          { headerText }
        </h1>
        <div className="Banner__subtitle">
          { subtitle }
        </div>
        <Button link={ buttonLink } color="gold">
          { buttonText }
        </Button>
      </div>
      <figure className="Banner__image">
        <img
          src={ imgFile }
          alt={ imgAltText }
        />
      </figure>
    </div>
  </section>
)

Banner.propTypes = {
  headerText: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  imgFile: PropTypes.string,
  imgAltText: PropTypes.string,
}

Banner.defaultProps = {
  headerText: '',
  subtitle: '',
  buttonText: '',
  buttonLink: '',
  imgFile: '',
  imgAltText: '',
};

export default Banner;
