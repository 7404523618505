import React from 'react';
import PropTypes from 'prop-types';
import { Highlight, HIGHLIGHT_SHAPE } from '../Highlight';
import './Highlights.scss';

const Highlights = ({highlights}) => (
  <section className="Highlights">
    {highlights.map(highlight => (
      <Highlight
        key={ highlight.header_text }
        imageFile={ highlight.img.image_file }
        imageAltText={ highlight.img.alt_text }
        headerText={ highlight.header_text }
        text={ highlight.text }
        color={ highlight.color }
      />
    ))}
  </section>
)

Highlights.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.shape(HIGHLIGHT_SHAPE)),
}

Highlights.defaultProps = {
  highlights: [],
}

export default Highlights;
