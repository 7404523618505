import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.scss';

const Carousel = ({sliderTitle, slide, sliderText}) => (
  <section className="Carousel">
    <div className="Carousel__wrapper">
      <h2 className="Carousel__title">
        { sliderTitle }
      </h2>
      <Slider
        dots={ true }
      >
      { slide.map(slide => (
        <div key={ slide.img.image_file }>
          <figure className="Carousel__slide-image">
            <img src={ slide.img.image_file } alt={ slide.img.alt_text } />
          </figure>
        </div>
      ))}
      </Slider>
      <div className="Carousel__text">
        { sliderText }
      </div>
    </div>
  </section>
)

Carousel.propTypes = {
  sliderTitle: PropTypes.string,
  slide: PropTypes.array,
  sliderText: PropTypes.string,
}

Carousel.defaultProps = {
  sliderTitle: '',
  slide: [],
  sliderText: '',
}

export default Carousel;
