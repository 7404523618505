import React from 'react';
import PropTypes from 'prop-types';
import './Quote.scss';

const Quote = ({text, author}) => (
  <section className="Quote">
    <div className="Quote__wrapper">
      <div className="Quote__text">
        { text }
      </div>
      <div className="Quote__author">
        { author }
      </div>
    </div>
  </section>
)

Quote.propTypes = {
  text: PropTypes.string,
  author: PropTypes.string,
}

Quote.defaultProps = {
  text: '',
  author: '',
}

export default Quote;
