import React from 'react';
import { graphql } from 'gatsby';
import Script from 'react-load-script';
import Helmet from 'react-helmet';

import Layout from '../components/Layout';
import Cta from '../components/Cta';
import Banner from '../components/Banner';
import Carousel from '../components/Carousel';
import Quote from '../components/Quote';
import Highlights from '../components/Highlights';

export default class HomePage extends React.Component {
  handleScriptLoad() {
    if (window.netlifyIdentity) {
      window.netlifyIdentity.on('init', user => {
        if (!user) {
          window.netlifyIdentity.on('login', () => {
            document.location.href = '/admin/'
          })
        }
      })
    }
    window.netlifyIdentity.init()
  }

  render() {
    const { frontmatter: cta } = this.props.data.ctaMarkdown;
    const { description, banner, slider, highlights, quote } = this.props.data.pageMarkdown.frontmatter;

    return (
      <Layout>
        <Helmet>
          <meta name="description" content={ description } />
        </Helmet>
        <Script
          url="https://identity.netlify.com/v1/netlify-identity-widget.js"
          onLoad={this.handleScriptLoad.bind(this)}
        />
        <Banner
          headerText={ banner.header_text }
          subtitle={ banner.subtitle }
          buttonLink={ banner.button.link }
          buttonText={ banner.button.text }
          imgFile={ banner.img.image_file }
          imgAltText={ banner.img.alt_text }
        />
        <Carousel
          sliderTitle={ slider.title }
          slide={ slider.slide }
          sliderText={ slider.text }
          buttonLink={ slider.button.link }
          buttonText={ slider.button.text }
        />
        <Highlights
          highlights={ highlights.highlight }
        />
        <Quote
          text={ quote.text }
          author={ quote.author }
        />
        <Cta
          title={ cta.title }
          buttonLink={ cta.button.link }
          buttonText={ cta.button.text }
        />
      </Layout>
    )
  }
}

export const homePageQuery = graphql`
  query HomePage($path: String!) {
    pageMarkdown: markdownRemark(frontmatter: { path: { eq: $path } }) {
      frontmatter {
        description
        banner {
          img {
            image_file
            alt_text
          }
          header_text
          subtitle
          button {
            text
            link
          }
        }
        slider {
          title
          slide {
            img {
              image_file
              alt_text
            }
          }
          text
          button {
            link
            text
          }
        }
        highlights {
          highlight {
            img {
              image_file
              alt_text
            }
            color
            header_text
            text
          }
        }
        quote {
          text
          author
        }
      }
    }
    ...cta
    ...siteTitle
  }
`
